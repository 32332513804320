import React from "react";
import { useNavigate } from "react-router-dom";

interface CustomerProps {
  item: any;
  index: number;
}

const NewsItems: React.FC<CustomerProps> = ({ item, index }) => {
  console.log("item",item?.url);
  const navigate = useNavigate();
  return (
    <div
      key={index}
      onClick={() => {
        navigate("/admin/add-achievement", { state: { item } });
      }}
      className="
        flex 
        my-2 
        border-[1px] 
        border-primary 
        rounded-2xl
        justify-between
        text-primary  
        hover:text-white 
        hover:bg-primary
        w-full
        cursor-pointer
        "
      >
      <div className="items-center w-full text-center">
        <img
          className="w-full h-[230px] sm:h-[200px] md:h-[200px] lg:h-[240px] rounded-tr-[15px] rounded-tl-[15px]"
          src={item?.url}
        />
        <div>{item?.title}</div>
      </div>
    </div>
  );
};
export default NewsItems;
