import { React, Suspense } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./Pages/Login/index.tsx";
import DashBoard from "./Pages/admin/Dashboard/index.tsx";
import AddEvent from "./Pages/admin/add-event/index.tsx";
import Customer from "./Pages/admin/customer/index.tsx";
import EditUser from "./Pages/admin/editUser/index.tsx";
import ListEvent from "./Pages/admin/list-event/index.tsx";
import MainLayout from "./component/MainLayout/index.tsx";
import { RXStore } from "./store/slice/configStore.tsx";
import AddNews from "./Pages/admin/add-news/index.tsx";
import ListNews from "./Pages/admin/list-news/index.tsx";
import ClubRule from "./Pages/admin/ClubRule/index.tsx";
import GolfLaw from "./Pages/admin/GolfLaw/index.tsx";
import MemberBenefits from "./Pages/admin/MemberBenefits/index.tsx";
import ListPayment from "./Pages/admin/ListPayment/index.tsx";
import PaymentDetail from "./Pages/admin/PaymentDetail/index.tsx";
import TongChi from "./Pages/admin/TongChi/index.tsx";
import ThemPhieuChi from "./Pages/admin/addPhieuChi/index.tsx";
import CLBHistory from "./Pages/admin/CLBHistory/index.tsx";
import AddClub from "./Pages/admin/add-club/index.tsx";
import ListClub from "./Pages/admin/list-club/index.tsx";
import AddFund from "./Pages/admin/AddFund/index.tsx";
import CustomerBirthday from "./Pages/admin/customerBirthDay/index.tsx";
import AddAchievement from "./Pages/admin/add-achievement/index.tsx";
import ListAchievement from "./Pages/admin/list-achievement/index.tsx";
import ListHioEagle from "./Pages/admin/list-hio/index.tsx";
import AddHioEagle from "./Pages/admin/add-Hio/index.tsx";
function App() {
  const loading = false;
  return (
    <>
      <RXStore />
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/" name="Login Page" element={<Login />} />
            <>
              <Route
                exact
                path="/dashboard"
                name="Dashboard"
                element={
                  <MainLayout>
                    <DashBoard />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/admin/customer"
                name="Customer"
                element={
                  <MainLayout>
                    <Customer />
                  </MainLayout>
                }
              />
              <Route
                exact
                path="/admin/editUser"
                name="Customer"
                element={
                  <MainLayout>
                    <EditUser />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/admin/list-event"
                name="List Event"
                element={
                  <MainLayout>
                    <ListEvent />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/admin/add-event"
                name="add Event"
                element={
                  <MainLayout>
                    <AddEvent />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/admin/add-news"
                name="add News"
                element={
                  <MainLayout>
                    <AddNews />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/admin/list-news"
                name="List News"
                element={
                  <MainLayout>
                    <ListNews />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/admin/add-achievement"
                name="add Achievement"
                element={
                  <MainLayout>
                    <AddAchievement />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/admin/list-achievement"
                name="List Achievement"
                element={
                  <MainLayout>
                    <ListAchievement />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/admin/add-hio-eagle"
                name="add HIO - Eagle"
                element={
                  <MainLayout>
                    <AddHioEagle />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/admin/list-hio-eagle"
                name="List HIO - Eagle"
                element={
                  <MainLayout>
                    <ListHioEagle />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/admin/club-rule"
                name="ClubRule"
                element={
                  <MainLayout>
                    <ClubRule />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/admin/golf-law"
                name="golflaw"
                element={
                  <MainLayout>
                    <GolfLaw />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/admin/membership-benefits"
                name="membership benefits"
                element={
                  <MainLayout>
                    <MemberBenefits />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/admin/list-payment"
                name="list payment"
                element={
                  <MainLayout>
                    <ListPayment />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/admin/payment-detail"
                name="Payment Detail"
                element={
                  <MainLayout>
                    <PaymentDetail />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/admin/tong-chi"
                name="Tong Chi"
                element={
                  <MainLayout>
                    <TongChi />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/admin/ThemPhieuChi"
                name="ThemPhieuChi"
                element={
                  <MainLayout>
                    <ThemPhieuChi />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/admin/lich-su-clb"
                name="CLB HISTORY"
                element={
                  <MainLayout>
                    <CLBHistory />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/admin/add-club"
                name="Add Club"
                element={
                  <MainLayout>
                    <AddClub />
                  </MainLayout>
                }
              />

              <Route
                exact
                path="/admin/list-club"
                name="List Club"
                element={
                  <MainLayout>
                    <ListClub />
                  </MainLayout>
                }
              />
              <Route
                exact
                path="/admin/Add-fund"
                name="add fund"
                element={
                  <MainLayout>
                    <AddFund />
                  </MainLayout>
                }
              />
              <Route
                exact
                path="/admin/CustomerBirthday"
                name="CustomerBirthday"
                element={
                  <MainLayout>
                    <CustomerBirthday />
                  </MainLayout>
                }
              />
            </>
          </Routes>
        </Suspense>
      </HashRouter>
    </>
  );
}

export default App;
