import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { BASE_URL, request } from "../../../api/request.tsx";
import LoadingWrap from "../../../component/LoadingWrap.tsx";
import { GetList } from "../../../hook/getList.tsx";

interface IFormInput {
  name: string;
  description: string;
}

const validationSchemaRegister = Yup.object().shape({
  name: Yup.string().required("Vui lòng điền tên sự kiện"),
  description: Yup.string().required("Vui lòng nhập mô tả cửa hàng"),
});

const AddNews = () => {
  const [loading, setLoading] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  const location = useLocation();
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const navigate = useNavigate();
  const { item } = location.state || {};
  const { data: detailNews } = GetList({
    url: `achievement/get-achievements-detail?achievement_id=${item?.id}`,
    isLazy: false,
  });
  useEffect(() => {
    if (item) {
      setisUpdate(true);
      setSelectedImage(item?.url);
    }
  }, [item]);

  useEffect(() => {
    if (detailNews) {
      setValue("description", detailNews?.description);
    }
  }, [detailNews]);

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<IFormInput>({
    defaultValues: {
      name: item?.title || "",
      description: detailNews?.description?.replace(/&lt;/g, "<") || "",
    },
    resolver: yupResolver<any>(validationSchemaRegister),
  });

  const handelOnchange = (value: any, field: any) => {
    setValue(field, value.target.value);
  };
  const MyEditormodules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const MyEditorformats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const notify = (title: string) => toast(title);

  const onSubmit = async (data: IFormInput) => {
    setLoading(true);
    if (selectedImage) {
      if (isUpdate) {
        onUpdate(data);
      } else {
        const body = {
          title: data.name,
          description: data.description,
          url: selectedImage,
        };
        const res = await request("achievement/create-achievements", body, "POST");
        notify(res?.message || "Đã có lỗi xảy ra, vui lòng thử lại sau");
        setTimeout(() => {
          if (res?.status) {
            // navigate(-1);
            navigate("/admin/list-achievement");
          }
        }, 500);
      }
    } else {
      notify("Vui lòng chọn hình ảnh");
    }

    setLoading(false);
  };

  const onUpdate = async (data: any) => {
    setLoading(true);
    const body = {
      title: data.name,
      description: data.description,
      url: selectedImage,
    };
    const res = await request(
      `achievement/update-achievements?achievement_id=${item.id}`,
      body,
      "POST"
    );
    notify(res?.message || "Đã có lỗi xảy ra, vui lòng thử lại sau");
    setLoading(false);
    setTimeout(() => {
      if (res?.status) {
        navigate(-1);
      }
    }, 500);
  };

  const handleImageChange = async (event: any) => {
    setLoading(true);
    setSelectedImage(URL.createObjectURL(event.target.files[0]));
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    try {
      const res = await fetch(BASE_URL + "upload/upload", {
        method: "POST",
        body: formData,
      });
      const resJson = await res.json();
      if (resJson?.status) {
        setSelectedImage(resJson?.data);
      } else {
        notify(resJson?.message || "Đã có lỗi xảy ra, vui lòng thử lại sau");
      }
    } catch (e) {
      notify("Đã có lỗi xảy ra, vui lòng thử lại sau");
    }
    setLoading(false);
  };

  const onDelete = () => {
    setLoading(true);
    request(`achievement/delete-achievements?achievement_id=${item.id}`, {}, "POST").then(
      (response) => {
        notify(response?.message || "Đã có lỗi xảy ra, vui lòng thử lại sau");
        setLoading(false);
        if (response?.status) {
          navigate(-1);
        }
      }
    );
  };

  const classInput = "w-full border-[1px] rounded-md px-2 py-2 outline-none";
  const classLable = "font-semibold";
  if (!detailNews && isUpdate) {
    <div className="flex h-screen">
      <LoadingWrap active={true}>
        <div></div>
      </LoadingWrap>
      ;
    </div>;
  } else
    return (
      <LoadingWrap active={loading}>
        <div className="w-full">
          <div className="text-2xl font-bold text-center text-primary">
            {isUpdate ? "CẬP NHẬT THÀNH TÍCH" : "THÊM THÀNH TÍCH"}
          </div>
          <div className="mb-3">
            <div className={classLable}>Tiêu đề thành tích</div>
            <input
              onChange={(value) => handelOnchange(value, "name")}
              placeholder="Nhập tiêu đề thành tích"
              className={classInput}
              defaultValue={getValues("name")}
            />
            <div>
              {errors?.name ? (
                <text style={{ color: "red" }}>{errors.name?.message}</text>
              ) : null}
            </div>
          </div>

          <div className="mb-3">
            {selectedImage && (
              <div>
                <h2>Ảnh đã chọn:</h2>
                <img
                  src={selectedImage}
                  alt="Selected"
                  style={{ maxWidth: "15%" }}
                />
              </div>
            )}
            <div className={classLable}>Ảnh thành tích</div>
            <input
              onChange={handleImageChange}
              type="file"
              className={classInput}
            />
          </div>

          <div className="mb-3">
            <div className={classLable}>Mô tả thành tích</div>
            <ReactQuill
              theme="snow"
              modules={MyEditormodules}
              formats={MyEditorformats}
              defaultValue={detailNews?.description?.replace(/&lt;/g, "<")}
              onChange={(txt) => {
                setValue("description", txt);
              }}
            />
            <div>
              {errors?.description ? (
                <text style={{ color: "red" }}>
                  {errors.description?.message}
                </text>
              ) : (
                <text className="h-2 bg-red-200 opacity-0">-</text>
              )}
            </div>
          </div>

          <div className="flex justify-center">
            <button
              onClick={handleSubmit(onSubmit)}
              color="primary"
              className="self-center px-8 py-2 mb-20 text-white rounded-md bg-primary"
            >
              {isUpdate ? "Cập nhật" : "Tạo mới"}
            </button>

            {isUpdate && (
              <button
                onClick={onDelete}
                color="primary"
                className="self-center px-8 py-2 mb-20 ml-2 text-white bg-red-500 rounded-md"
              >
                {"Xoá"}
              </button>
            )}
          </div>
        </div>
        <ToastContainer />
      </LoadingWrap>
    );
};

export default AddNews;
