import React from "react";
import {
  FaChartLine,
  FaCodeBranch,
  FaSignOutAlt,
  FaUser,
  FaCcDinersClub,
  FaCashRegister,
  FaHistory,
  FaGift,
  FaLayerGroup,
  FaNewspaper,
  FaArchive
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  authSelect,
  setIsLogin,
  setToken,
  setUserInfo,
} from "../../store/slice/auth.slice.tsx";
import { AiFillEnvironment } from "react-icons/ai";

const dataRouteAdmin = [
  {
    lable: "Thống Kê",
    id: 1,
    route: [
      { path: "/dashboard", lable: "Thống kê", id: 1, icon: <FaChartLine /> },
    ],
  },
  {
    lable: "Người dùng",
    id: 2,
    route: [
      { path: "/admin/customer", lable: "Người dùng", id: 2, icon: <FaUser /> },
    ],
  },
  {
    lable: "CLB",
    id: 1,
    route: [
      { path: "/admin/list-club", lable: "Danh sách CLB", id: 1, icon: <FaLayerGroup /> },
      { path: "/admin/add-club", lable: "Thêm CLB", id: 2, icon: <FaLayerGroup /> },
    ],
  },

  {
    lable: "Tin tức",
    id: 3,
    route: [
      {
        path: "/admin/list-news",
        lable: "Danh sách tin tức",
        id: 1,
        icon: <FaNewspaper />,
      },
      {
        path: "/admin/add-news",
        lable: "Thêm tin tức",
        id: 2,
        icon: <FaNewspaper />,
      },
    ],
  },

  {
    lable: "Thành tích nổi bật",
    id: 5,
    route: [
      {
        path: "/admin/list-achievement",
        lable: "Danh sách thành tích",
        id: 1,
        icon: <FaArchive />,
      },
      {
        path: "/admin/add-achievement",
        lable: "Thêm thành tích",
        id: 2,
        icon: <FaArchive />,
      },
    ],
  },

  {
    lable: "HIO - Eagle",
    id: 6,
    route: [
      {
        path: "/admin/list-hio-eagle",
        lable: "Danh sách HIO - Eagle",
        id: 1,
        icon: <FaArchive />,
      },
      {
        path: "/admin/add-hio-eagle",
        lable: "Thêm HIO - Eagle",
        id: 2,
        icon: <FaArchive />,
      },
    ],
  },

  {
    lable: "Tin tức",
    id: 3,
    route: [
      {
        path: "/admin/list-news",
        lable: "Danh sách tin tức",
        id: 3,
        icon: <FaCodeBranch />,
      },
      {
        path: "/admin/add-news",
        lable: "Thêm tin tức",
        id: 4,
        icon: <FaCodeBranch />,
      },
    ],
  },

  {
    lable: "Sự kiện",
    id: 5,
    route: [
      {
        path: "/admin/list-event",
        lable: "Danh sách sự kiện",
        id: 9,
        icon: <AiFillEnvironment />,
      },
      {
        path: "/admin/add-event",
        lable: "Thêm sự kiện",
        id: 10,
        icon: <AiFillEnvironment />,
      },
    ],
  },

  {
    lable: "Điều lệ",
    id: 3,
    route: [
      {
        path: "/admin/club-rule",
        lable: "Điều lệ CLB",
        id: 3,
        icon: <FaCcDinersClub />,
      },
      {
        path: "/admin/golf-law",
        lable: "Luật Golf",
        id: 4,
        icon: <FaCodeBranch />,
      },

      {
        path: "/admin/membership-benefits",
        lable: "Quyền lợi hội viên",
        id: 4,
        icon: <FaGift />,
      },

      {
        path: "/admin/lich-su-clb",
        lable: "Lịch sử CLB",
        id: 4,
        icon: <FaHistory />,
      },
    ],
  },

  {
    lable: "Quỹ CLB",
    id: 3,
    route: [
      {
        path: "/admin/list-payment",
        lable: "Tổng thu",
        id: 3,
        icon: <FaCashRegister />,
      },
      {
        path: "/admin/tong-chi",
        lable: "Tổng chi",
        id: 4,
        icon: <FaCashRegister />,
      },
      {
        path: "/admin/Add-fund",
        lable: "Tiền tài trợ",
        id: 4,
        icon: <FaCashRegister />,
      },
    ],
  },
];

export default function NavBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector(authSelect);

  const dataNav = dataRouteAdmin; // nhưng thứ sẽ hiện ra ở thanh navbar ( đối với loại màn hình to)
  return (
    <div className="h-full min-h-screen px-10 py-5 border-r-2 bg-primary">
      <div className="py-4">
        <div className="flex items-center">
          {/* <img
            src={images.logo}
            alt="Logo"
            className="w-[70px] h-[70px] object-contain"
          /> */}
          <div className="ml-2 text-2xl font-bold text-white">GOLF - CLUB</div>
        </div>
        <div className="mt-5">
          {dataNav.map((item, index) => {
            return (
              <div className="px-5 py-0 rounded-xl" key={index + "navRoute"}>
                {item.route.length > 1 && (
                  <div className="mt-5 font-bold">{item.lable}</div>
                )}
                <div>
                  {item.route.map((route, subindex) => {
                    const Icon = route.icon;
                    return (
                      <div
                        onClick={() => {
                          navigate(route.path);
                        }}
                        className={`bg-[white] 
                        rounded-xl
                        px-2 
                        mt-1 
                        hover:bg-orange-500
                        cursor-pointer
                        flex
                        items-center
                        min-h-[40px]
                        `}
                        key={subindex}
                      >
                        <div>{route.icon}</div>
                        <div className="ml-2">{route.lable}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
          <div className="flex justify-center my-5">
            <button
              onClick={() => {
                dispatch(setIsLogin(false));
                dispatch(setToken(""));
                dispatch(setUserInfo({}));
                navigate("/")
              }}
              className="flex items-center self-center text-xl text-white"
            >
              Đăng xuất
              <FaSignOutAlt className="ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
